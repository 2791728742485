<script>
import { required, email, integer, minLength, maxLength } from "vuelidate/lib/validators";
import {
  authFackMethods,
  notificationMethods
} from "@/state/helpers";
import router from "@/router";

export default {
  data() {
    return {
      email: "",
      submitted: false,
      // error: null,
      tryingToReset: false,
      isResetError: false,
      requestSend: false,
      code: null,
      password: null,
      loading: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations () {
    const localRules = {
      email: { required, email }
    }
    if (this.requestSend) {
      // if billing is not the same as shipping, require it
      localRules.code = { required, integer, minLength: minLength(6), maxLength: maxLength(6) }
      localRules.password = { required, minLength: minLength(6) }
    }
    return localRules
  },
  // validations: {
  //   email: { required, email },
  //   code: { required, integer, minLength: minLength(6), maxLength: maxLength(6) },
  //   password: { required, minLength: minLength(6) },
  // },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    ...authFackMethods,
    ...notificationMethods,
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      this.loading = true

      const { email } = this;
      this.requestPasswordReset({ email: email }).then(() => {
        this.requestSend = true
        this.loading = false
        this.$store.dispatch('notification/error', null, { root: true });
      })
    },

    tryToSend() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      this.loading = true
      const { email, password, code } = this;
      this.resetPassword({ email, password, code }).then(() => {
        this.requestSend = true
        this.loading = false
        this.$store.dispatch('notification/error', null, { root: true });
        router.push('/');
      },error => {
        this.loading = false
        if (error.error === 'code error') {
          this.$store.dispatch('notification/error', this.$t('Authentication.ForgotPassword.Errors.WrongCode'), { root: true });
          return
        }
        this.$store.dispatch('notification/error', this.$t('General.Errors.UnexpectedError'), { root: true });
      })

    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="https://eefim.com">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="https://eefim.com" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="20" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">{{$t('Authentication.ForgotPassword.Title')}}</h4>
                        <p class="text-muted">{{$t('Authentication.ForgotPassword.Description')}}</p>
                      </div>

                      <b-alert v-if="requestSend" variant="success" show dismissible>
                        {{$t('Authentication.ForgotPassword.AlertProcess')}}
                      </b-alert>

                      <div class="p-2 mt-5">

                        <b-alert
                            variant="danger"
                            class="mb-4"
                            v-if="notification.message"
                            show
                            dismissible
                        >{{notification.message}}</b-alert>

                        <form v-if="!requestSend" class="form-horizontal" @submit.prevent="tryToReset">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">
                              {{$t('Authentication.ForgotPassword.Form.Email')}}
                            </label>
                            <input
                              v-model="email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              :placeholder="$t('Authentication.ForgotPassword.Form.EmailPlaceholder')"
                              :class="{ 'is-invalid': submitted && $v.email.$error }"
                              :disabled="requestSend"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">
                                {{$t('Authentication.ForgotPassword.Errors.EmailRequired')}}
                              </span>
                              <span v-if="!$v.email.email">
                                {{$t('Authentication.ForgotPassword.Errors.ErrorInEmail')}}
                              </span>
                            </div>
                          </div>
                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              {{$t('Authentication.ForgotPassword.Form.Send')}}
                              <b-spinner v-if="loading" small class="align-middle mr-1" variant="light" role="status">
                              </b-spinner>
                            </button>
                          </div>
                        </form>

                        <form v-if="requestSend"  class="form-horizontal" @submit.prevent="tryToSend">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="code">{{$t('Authentication.ForgotPassword.Form.Code')}}</label>
                            <input
                                v-model="code"
                                type="number"
                                class="form-control"
                                id="code"
                                :placeholder="$t('Authentication.ForgotPassword.Form.CodePlaceholder')"
                                :class="{ 'is-invalid': submitted && $v.code.$error }"
                            />
                            <div v-if="submitted && $v.code.$error" class="invalid-feedback">
                              <span v-if="!$v.code.required">
                                {{$t('Authentication.ForgotPassword.Errors.CodeRequired')}}
                              </span>
                              <span v-if="!$v.code.integer">
                                {{$t('Authentication.ForgotPassword.Errors.CodeOnlyNumbers')}}
                              </span>
                              <span v-if="!$v.code.minLength">
                                {{$t('Authentication.ForgotPassword.Errors.CodeMinLength')}}
                              </span>
                              <span v-if="!$v.code.maxLength">
                                {{$t('Authentication.ForgotPassword.Errors.CodeMaxLength')}}
                              </span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">
                              {{$t('Authentication.ForgotPassword.Form.Password')}}
                            </label>
                            <input
                                v-model="password"
                                type="password"
                                class="form-control"
                                id="userpassword"
                                :placeholder="$t('Authentication.ForgotPassword.Form.PasswordPlaceholder')"
                                :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                                v-if="submitted && !$v.password.required"
                                class="invalid-feedback"
                            >{{$t('Authentication.ForgotPassword.Errors.PasswordNeedNew')}}</div>
                            <div
                                v-if="submitted && !$v.password.minLength"
                                class="invalid-feedback"
                            >{{$t('Authentication.ForgotPassword.Errors.PasswordErrorCountSymbol')}}</div>

                          </div>

                          <div class="mt-4 text-center">
                            <button
                                class="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                            >
                              {{$t('Authentication.ForgotPassword.Form.Send')}}
                              <b-spinner v-if="loading" small class="align-middle mr-1" variant="light" role="status">
                              </b-spinner>
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          {{$t('Authentication.ForgotPassword.RememberPass')}}
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                          >
                            {{$t('Authentication.ForgotPassword.Authorization')}}
                          </router-link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
